<template>
  <!-- 侵权报案 -->
  <div class="padding24" id="tort_list">
    <div v-show="$route.meta.isShow">
      <div class="top">
        <div></div>
        <div class="right">
          <span>
            <a-select
              v-model="getData.auditStatus"
              style="width: 120px"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              @change="auditStatusChange"
            >
              <a-select-option v-for="item in auditStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </span>
          <a-select
            v-model="getData.type"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="typeChange"
          >
            <a-select-option v-for="item in typeArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <a-input
            v-model="getData.fullName"
            placeholder="请输入投诉人姓名"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            :disabled="searchDis"
            icon="search"
            type="primary"
            @click="searchClick"
          >
            搜索
          </a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="type" slot-scope="type, record">
          <span v-if="record.type == 1">线上</span>
          <span v-if="record.type == 2">线下</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a v-if="isShowDetail" class="mr20" @click="detailClick(record)">
            详情
          </a>
          <a v-if="isShowStatus" class="mr20" @click="statusClick(record)">
            修改状态
          </a>
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="getData.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="tableData.length > 0"
      />
      <!-- 修改状态弹框 -->
      <ModifyStatus
        v-if="modifyStatusVis"
        :visible="modifyStatusVis"
        :id="currentId"
        :fAuditStatus="currentAuditStatus"
        @cancel="() => (modifyStatusVis = false)"
        @ok="modifyStatusOk"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { TortListApi } from "@/request/api/crm";
import MyPagination from "@/components/pagination/MyPagination.vue";
import ModifyStatus from "../modules/ModifyStatus.vue";
import { codeFn } from "@/utils/tools";
import { message } from "ant-design-vue";
export default {
  components: { MyPagination, ModifyStatus },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    this.auditStatusArr.push(...dictionaryObj.tortReportAudit);
    if (codeFn("/admin/tort/report/getOne")) {
      this.isShowDetail = true;
    }
    if (codeFn("/admin/tort/report/audit")) {
      this.isShowStatus = true;
    }
    this.listFn();
  },
  data() {
    return {
      getData: {
        pageNo: 1,
        pageSize: 20,
        type: "",
        fullName: "",
        auditStatus: "",
      },
      columns: [
        {
          title: "报案号",
          dataIndex: "bizNo",
          key: "bizNo",
          width: "10%",
        },
        {
          title: "投诉人姓名",
          dataIndex: "fullName",
          key: "fullName",
          width: "10%",
        },
        {
          title: "投诉人公司",
          dataIndex: "company",
          key: "company",
          width: "20%",
        },
        {
          title: "投诉人电话",
          dataIndex: "phone",
          key: "phone",
          width: "10%",
        },
        {
          title: "侵权方式",
          dataIndex: "type",
          key: "type",
          width: "10%",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "侵权信息",
          dataIndex: "address",
          key: "address",
          width: "20%",
        },
        {
          title: "审核状态",
          dataIndex: "auditStatusText",
          key: "auditStatusText",
          width: "10%",
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          width: "10%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      typeArr: [
        { key: "", value: "全部方式" },
        { key: 1, value: "线上" },
        { key: 2, value: "线下" },
      ],
      auditStatusArr: [{ key: "", value: "全部状态" }],
      count: 0,
      currentId: "",
      currentAuditStatus: 0,
      tableLoading: false,
      searchDis: false,
      modifyStatusVis: false,
      isShowDetail: false,
      isShowStatus: false,
    };
  },
  //   watch: {
  //     $route: {
  //       handler(to, from) {
  //         if (to.path == "/crm/tortList") {
  //           this.listFn();
  //         }
  //       },
  //       immediate: true,
  //     },
  //   },
  methods: {
    typeChange(value) {
      this.getData.type = value;
      this.listFn();
    },
    auditStatusChange(value) {
      this.getData.auditStatus = value;
      this.listFn();
    },
    searchClick() {
      this.searchDis = true;
      setTimeout(() => {
        this.searchDis = false;
      }, 3000);
      this.getData.pageNo = 1;
      this.listFn();
    },
    detailClick(record) {
      this.$router.push(`/crm/tortDetail/${record.id}`);
    },
    // 修改状态
    statusClick(record) {
      if (record.auditStatus == 11) {
        message.error("此案件已结案");
        return;
      }
      this.currentAuditStatus = record.auditStatus;
      this.currentId = record.id;
      this.modifyStatusVis = true;
    },
    modifyStatusOk() {
      this.modifyStatusVis = false;
      this.listFn();
    },
    listFn() {
      this.tableLoading = true;
      TortListApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          this.count = data.count;
          data.data.map((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#tort_list");
    },
  },
};
</script>

<style lang="less" scoped>
#tort_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .right {
    span {
      margin-right: 10px;
    }
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
  }
}
/deep/.el-step__head.is-process {
  color: #303133;
  border-color: #303133;
}
/deep/.ant-table-wrapper {
  margin-top: 20px;
}
</style>
