<template>
  <!-- 修改状态弹框 -->
  <a-modal
    :visible="visible"
    title="修改状态"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    width="40%"
    @cancel="() => $emit('cancel')"
    @ok="ok"
  >
    <div class="line">
      <div class="left">审核状态：</div>
      <div class="right">
        <a-select
          v-model="auditStatus"
          style="width: 200px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="auditStatusChange"
        >
          <a-select-option v-for="item in auditStatusArr" :key="item.key">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { TortAuditApi, TortSelectApi } from "@/request/api/crm";
import { message } from "ant-design-vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    fAuditStatus: {
      type: Number,
      default: 0,
    },
  },
  created() {
    TortSelectApi({ auditStatus: this.fAuditStatus }).then(({ code, data }) => {
      if (code == 200) {
        this.auditStatusArr = data.tortReportAudit;
      }
    });
  },
  data() {
    return {
      auditStatus: "",
      auditStatusArr: [],
      confirmLoading: false,
    };
  },
  methods: {
    auditStatusChange(value) {
      this.auditStatus = value;
    },
    ok() {
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
      }, 2000);
      if (!this.auditStatus) {
        message.error("请选择审核状态");
        return;
      }
      TortAuditApi({ id: this.id, auditStatus: this.auditStatus }).then(
        ({ code }) => {
          if (code == 200) {
            message.success("修改状态成功");
            this.$emit("ok");
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.line {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #00153c;
  .left {
    width: 100px;
    text-align: right;
  }
}
</style>
